<template>
  <div>
    <welcome-conference-banner v-if='false' 
      :show-scroll-arrows='false'
      :show-dots='false'
      background-color='#F9F6F9' />
    <div class='relative pb-8'
      style='min-height:calc(100vh - 20rem);'>
      <content-background-cover
      class='z-0'
      :top-offset='420'
      custom-css='background:linear-gradient(180deg, #FFFFFF 0%, #FDF0FDAA 42.03%);' />
      <div class='banner-carousel'>
        <vue-slick-carousel v-if='eventConfigWelcomeImages.length' v-bind='settings'>
          <div v-for='(welcomeImage, index) in eventConfigWelcomeImages' :key='`welcomeImage-${index}`'>
            <picture
              :class='hasLinkCssClass(welcomeImage.siteUrl)'
              class='block mx-auto w-full'
              @click='clickImage(welcomeImage.siteUrl)'>
              <img :src='welcomeImage.welcomeImageUrl'>
            </picture>
          </div>
        </vue-slick-carousel>
      </div>
      <div v-if='false' 
        class='full-width-inside-layout-container overflow-hidden border-b' style='max-width: calc(100vw - 0.5rem);'>
        <notices-rolling-banner
          background-color='#FFFFFF'
          class='full-width-inside-layout-container'
          style='height:56px; max-width:calc(100vw - 0.5rem);'
          custom-container-style='border-color: rgb(251 207 232);'
          custom-dot-style='background-color: rgb(251 207 232);' />
      </div>
      <div class='my-6 lg:my-10 px-4 flex flex-row justify-center lg:gap-x-4 gap-x-2'>
        <button
          @click='showSearchModal' 
          class='py-3 px-8 text-sm rounded-full bg-white shadow opacity-70 uppercase text-gray-900 font-semibold flex flex-row gap-x-2 justify-center items-center'>
          <search-icon class='h-5' />
          Search
        </button>
        <button
          @click='goToMyPage' 
          class='py-3 px-8 text-sm rounded-full bg-white shadow opacity-70 uppercase text-gray-900 font-semibold flex flex-row gap-x-2 justify-center items-center'>
          <heart-icon class='h-5' />
          My Page
        </button>
      </div>
      <gbcc2024-main-items
        :items='items' 
        class='lg:mb-4 mb-0'/>
      <gbcc2024-main-sub-items 
        :items='subItems'
        class='lg:my-4 my-0'/>
    </div>
  </div>
</template>

<script>
import { mapGetters }            from 'vuex'
import { SearchIcon, HeartIcon } from 'vue-tabler-icons'
import ContentBackgroundCover  from '@/components/ContentBackgroundCover.vue'
import NoticesRollingBanner    from '@/components/NoticesRollingBanner.vue'
import WelcomeConferenceBanner from '@/components/welcome/WelcomeConferenceBanner.vue'
import Gbcc2024MainItems       from '@/components/gbcc2024/Gbcc2024MainItems.vue'
import Gbcc2024MainSubItems    from '@/components/gbcc2024/Gbcc2024MainSubItems.vue'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Main',
  components: {
    SearchIcon,
    HeartIcon,
    ContentBackgroundCover,
    Gbcc2024MainItems,
    Gbcc2024MainSubItems,
    NoticesRollingBanner,
    WelcomeConferenceBanner,
    VueSlickCarousel,
  },
  data () {
    return {
      settings: {
        'lazyLoad': 'ondemand',
        'arrows': true,
        'dots': false,
        'dotsClass': 'slick-dots',
        'focusOnSelect': true,
        'infinite': true,
        'speed': 500,
        'autoplay': true,
        'autoplaySpeed': 10000,
        'slidesToShow': 1,
        'slidesToScroll': 1,
        'touchThreshold': 6,
      }
    }
  },
  computed: {
    ...mapGetters('events', [
      'eventConfigWelcomeImages',
    ]),
    items () {
      return [
        {
          title: 'Program',
          titleCssStyle: 'color:#AA4A7F;font-weight:600;',
          cssClass: 'gap-y-2',
          routeName: 'Programs',
          iconImgUrl: 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2023/icon_program.png',
          iconImgCssClass: 'h-6 w-6',
        },
        {
          title: 'Speakers',
          titleCssStyle: 'color:#AA4A7F;font-weight:600;',
          cssClass: 'gap-y-2',
          routeName: 'Speakers',
          iconImgUrl: 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2023/icon_speakers.png',
          iconImgCssClass: 'h-6 w-6',
        },
        {
          title: 'Abstracts',
          titleCssStyle: 'color:#AA4A7F;font-weight:600;',
          cssClass: 'gap-y-2',
          routeName: 'Abstracts',
          iconImgUrl: 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2023/icon_abstracts.png',
          iconImgCssClass: 'h-6 w-6',
        },
        {
          title: 'Floor Plan',
          titleCssStyle: 'color:#AA4A7F;font-weight:600;',
          cssClass: 'gap-y-2',
          routeName: 'Gbcc2024FloorPlan',
          iconImgUrl: 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2023/icon_floor_plan.png',
          iconImgCssClass: 'h-6 w-6',
        },
        {
          title: 'Leaflet',
          titleCssStyle: 'color:#AA4A7F;font-weight:600;',
          cssClass: 'gap-y-2',
          routeName: 'Gbcc2024Leaflet',
          iconImgUrl: 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2024/leaflet_icon.png',
          iconImgCssClass: 'h-6 w-6',
        },
        {
          title: 'Events',
          titleCssStyle: 'color:#AA4A7F;font-weight:600;',
          cssClass: 'gap-y-2',
          routeName: 'PhotoEvent',
          iconImgUrl: 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2024/stamp_icon.png',
          iconImgCssClass: 'h-6 w-6',
        },
      ]
    },  
    subItems () {
      return [
        {
          size: 'large',
          type: 'external_link',
          title: "Voting",
          titleCssStyle: 'color:#AA4A7F;font-weight:600;',
          cssStyle: 'background:#FFFFFF;',
          routeName: 'https://www.sympotalk.com/m/vote?eventSeq=309',
          iconImgUrl: 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2023/icon_votings.png',
          iconImgCssStyle: '',
        },
        {
          size: 'large',
          type: 'external_link',
          title: "Q&A",
          titleCssStyle: 'color:#AA4A7F;font-weight:600;',
          cssStyle: 'background:#FFFFFF;',
          routeName: 'https://www.sympotalk.com/m/room_list?eventSeq=309',
          iconImgUrl: 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2024/icon_qna.png',
          iconImgCssStyle: 'width: 48px; height: 48px;',
        },
        {
          title: 'Youtube',
          type: 'external_link',
          titleCssStyle: 'color:#701515;',
          cssStyle: 'background:#FFFFFF;',
          routeName: 'https://www.youtube.com/channel/UCYi4I2P4oySdy149MzEggfA/featured',
          iconImgUrl: 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2023/icon_youtube.png',
          iconImgCssStyle: '',
        },
        {
          title: 'Instagram',
          type: 'external_link',
          titleCssStyle: 'color:#B91A53;',
          cssStyle: 'background:#FFFFFF;',
          routeName: 'https://www.instagram.com/gbcc.tv/',
          iconImgUrl: 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2024/logo_instagram.png',
          iconImgCssStyle: '',
        },
        {
          title: 'Facebook',
          type: 'external_link',
          titleCssStyle: 'color:#153870;',
          cssStyle: 'background:#FFFFFF;',
          routeName: 'https://www.facebook.com/GBCCTV/',
          iconImgUrl: 'https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/gbcc2023/icon_facebook.png',
          iconImgCssStyle: 'border-radius:8px;',
        },

      ]
    },
  },
  methods: {
    showSearchModal () {
      this.$modal.show('search-modal')
    },
    goToMyPage () {
      this.$router.push({ name: 'MyPage' })
    },
    clickImage (url) {
      if (url) {
        if (url.indexOf('http') != -1) {
          window.open(url, '_blank')
        } else {
          window.open(`https://${url}`, '_blank')
        }
      } else {
        // do nothing
      }
    },
    hasLinkCssClass (url) {
      return (url) ? 'cursor-pointer' : ''
    },
  }
}
</script>

<style type="scss">
  .banner-carousel .slick-next {
    @apply right-4 z-10;
  }
  .banner-carousel .slick-prev {
    @apply left-4 z-10;
  }
</style>