<template>
  <div class='relative'>
    <content-background-cover
      :background-color='backgroundColor'
      :custom-css='customCssBackground'
      :top-offset='0'
      class='z-0' />
    <vue-slick-carousel v-if='notices.length' 
      v-bind='carouselSettings'>
      <div v-for='(notice, index) in notices' 
        :key='`rolling-notice-${index}`'
        class='px-2 relative min-w-full border-t border-b'
        :style='customContainerStyle'>
        <div class='flex flex-row justify-start items-center gap-x-2'>
          <div class='h-2 w-2 rounded-full'
            :style='customDotStyle'> </div>
          <div class='pl-4 pr-12 py-4 w-full lg:w-auto overflow-x-hide truncate hover:bg-gray-100 cursor-pointer border-r border-pink-200'
            @click='clickNotice(notice)'>
            {{ notice.title }}
          </div>
        </div>
      </div>
    </vue-slick-carousel>
  </div>
</template>

<script>
import { mapState, mapActions }         from 'vuex'
import ContentBackgroundCover from '@/components/ContentBackgroundCover.vue'
import VueSlickCarousel       from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'NoticesRollingBanner',
  props: [
  'backgroundColor',
  'customCssBackground',
  'customImageCss',
  'customContainerStyle',
  'customDotStyle',
  ],
  components: {
    VueSlickCarousel,
    ContentBackgroundCover,
  },
  computed: {
    ...mapState('notices', [
      'notices',
    ]),
    isMobile () {
      return window.navigator.userAgent.includes('Android') ||
             window.navigator.userAgent.includes('iPhone')
    },
    carouselSettings () {
      return {
        'arrows': false,
        'dots': false,
        'pauseOnHover': true,
        'variableWidth': true,
        'infinite': true,
        'slidesToShow': 1,
        'slidesToScroll': 1,
        'autoplay': true,
        'speed': this.rollingSpeed,
        'autoplaySpeed': 1000,
        'cssEase': 'linear'
      }
    },
    rollingSpeed () {
      return (this.isMobile) ? 3000 : 8000
    },
  },
  methods: {
    ...mapActions('notices', [
      'getNotices',
    ]),
    clickNotice (notice) {
      this.$router.push({ name: 'Notices', query: { notice_id: notice.id } })
    },  
  },
  mounted () {
    this.getNotices()
  }
}
</script>
