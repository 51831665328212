<template>
  <div class='flex flex-row flex-wrap gap-2 justify-center p-4 container'>
    <article v-for='(item, index) in items' 
      :key='`item-${index}`'
      class='originalStyles'
      @click='goToItem(item.routeName)'>
      <div class='glows'></div>
      <img
        v-if='showIconImage'
        :src='item.iconImgUrl'
        class='block mx-auto'
        :class='item.iconImgCssClass' />
      <h2 
        class='text-center uppercase text-sm lg:text-xl cursor-pointer font-black'
        :style='item.titleCssStyle'>{{ item.title }}</h2>
    </article>
  </div>
</template>

<script>

export default {
  name: 'Gbcc2024MainItems',
  components: {
  },
  data () {
    return {
      CONTAINER: null,
      CARDS: null,
      CONFIG: null,
    }
  },
  props: [
    'items',
  ],
  computed: {
    showIconImage () {
      return false // they don't want icons for now
    },
  },
  methods: {
    goToItem (routeName) {
      this.$router.push({ name: routeName })
    },
    updateStyle(event) {

      for (const CARD of this.CARDS) {

        // Check the card against the proximity and then start updating
        const CARD_BOUNDS = CARD.getBoundingClientRect()
        // Get distance between pointer and outerbounds of card
        if (
          (event === null || event === void 0 ? void 0 : event.x) > CARD_BOUNDS.left - this.CONFIG.proximity &&
          (event === null || event === void 0 ? void 0 : event.x) < CARD_BOUNDS.left + CARD_BOUNDS.width + this.CONFIG.proximity &&
          (event === null || event === void 0 ? void 0 : event.y) > CARD_BOUNDS.top - this.CONFIG.proximity &&
          (event === null || event === void 0 ? void 0 : event.y) < CARD_BOUNDS.top + CARD_BOUNDS.height + this.CONFIG.proximity) {
          // If within proximity set the active opacity
          CARD.style.setProperty('--active', 1)
        } else {
          CARD.style.setProperty('--active', this.CONFIG.opacity)
        }
        const CARD_CENTER = [
        CARD_BOUNDS.left + CARD_BOUNDS.width * 0.5,
        CARD_BOUNDS.top + CARD_BOUNDS.height * 0.5]

        let ANGLE = Math.atan2((event === null || event === void 0 ? void 0 : event.y) - CARD_CENTER[1], (event === null || event === void 0 ? void 0 : event.x) - CARD_CENTER[0]) * 180 / Math.PI
        ANGLE = ANGLE < 0 ? ANGLE + 360 : ANGLE
        CARD.style.setProperty('--start', ANGLE + 90)
      }
    },
    RESTYLE() {
      this.CONTAINER.style.setProperty('--blur', this.CONFIG.blur)
      this.CONTAINER.style.setProperty('--spread', this.CONFIG.spread)
    },
  },
  mounted () {
    
    this.CONTAINER = document.querySelector('.container')
    this.CARDS = document.querySelectorAll('article')
    document.body.addEventListener('pointermove', this.updateStyle)


    this.CONFIG = {
      proximity: 40,
      spread: 80,
      blur: 20,
      vertical: false,
      opacity: 0 
    }
    this.RESTYLE()
  },
}
</script>

<style>

:root {
  --border: #A44480;
  --card: #FFF;
  --border-width: 2px;
  --border-radius: 12px;
  --gradient: conic-gradient(from 180deg at 50% 70%, #A44480 0deg, #153882 72.0000010728836deg);
}

@property --start {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

article {
  --active: 0.15;
  --start: 0;
  height: 100%;
  background: var(--card);
  border-radius: var(--border-radius);
  position: relative;
}


.glows {
  pointer-events: none;
  position: absolute;
  inset: 0;
  filter: blur(calc(var(--blur) * 1px));
}

.glows::after,
.glows::before {
  --alpha: 0;
  content: "";
  background: var(--gradient);
  background-attachment: fixed;
  position: absolute;
  inset: -5px;
  border: 4px solid transparent;
  border-radius: var(--border-radius);
  mask:
    linear-gradient(#0000, #0000),
    conic-gradient(from calc((var(--start) - (var(--spread) * 0.5)) * 1deg), #000 0deg, #fff, #0000 calc(var(--spread) * 1deg));
  mask-composite: intersect;
  mask-clip: padding-box, border-box;
  opacity: var(--active);
  transition: opacity 1s;
}

article::before {
  position: absolute;
  inset: 0;
  border: var(--border-width) solid transparent;
  content: "";
  border-radius: var(--border-radius);
  pointer-events: none;
  background: var(--border);
  background-attachment: fixed;
  border-radius: var(--border-radius);
  mask:
    linear-gradient(#0000, #0000),
    conic-gradient(
    from calc(((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 1.5)) * 1deg),
    hsl(0 0% 100% / 0.15) 0deg,
    white,
    hsl(0 0% 100% / 0.15) calc(var(--spread) * 2.5deg));
  mask-clip: padding-box, border-box;
  mask-composite: intersect;
  opacity: var(--active);
  transition: opacity 1s;
}

article::after {
  --bg-size: 100%;
  content: "";
  pointer-events: none;
  position: absolute;
  background: var(--gradient);
  background-attachment: fixed;
  border-radius: var(--border-radius);
  opacity: var(--active, 0);
  transition: opacity 1s;
  --alpha: 0;
  inset: 0;
  border: var(--border-width) solid transparent;
  mask:
    linear-gradient(#0000, #0000),
    conic-gradient(from calc(((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 0.5)) * 1deg), #0000 0deg, #fff, #0000 calc(var(--spread) * 0.5deg));
  filter: brightness(1.5);
  mask-clip: padding-box, border-box;
  mask-composite: intersect;
}



.dg.ac {
  z-index: 99999 !important;
}


.originalStyles {
  @apply w-24 h-28 flex-grow flex flex-col justify-center rounded-xl bg-white shadow-lg border-2 border-pink-200;
}
</style>