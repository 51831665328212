<template>
  <div class=''>
    <div class='row-centered justify-between'>
      <h2 class='text-lg font-semibold uppercase'>{{ title }}</h2>
      <button 
        @click='goToNoticesPage'
        class='text-xs hover:bg-gray-100 p-2 rounded-md uppercase'>
        {{ viewMoreText }}
      </button>
    </div>
    <div v-for='(notice, index) in topFourNotices' 
      :key='`notice-${index}`'
      class='py-2 px-2 rounded-md lg:rounded-none w-full flex flex-row justify-between items-center bg-white text-sm border-b border-gray-100 cursor-pointer'
      @click='clickNotice(notice)'>
      {{notice.title}}
      <div v-if='!isEventGbcc2024' class='opacity-60 flex-shrink-0 w-1/3 lg:w-1/6 text-right'>
        {{noticeDate(notice.createdAt)}}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'NoticesPreviewList',
  props: {
    title: {
      type: String,
      default: '공지사항',
    },
  },
  computed: {
    ...mapState('notices', [
      'noticesPreivew',
    ]),
    ...mapGetters('events', [
      'showingEventId',
      'showTextInKorean',
    ]),
    topFourNotices () {
      let copied = this.noticesPreivew
      return copied.sort((a, b) => {
        return a.orderIndex - b.orderIndex
      }).slice(0, 4)
    },
    viewMoreText () {
      return this.showTextInKorean ? '더보기' : 'more'
    },
    isEventGbcc2024 () {
      return this.showingEventId === 39
    },
  },
  methods: {
    ...mapActions('notices', [
      'getNoticesPreview',
    ]),
    noticeDate (date) {
      return dayjs(date).format('YYYY-MM-DD')
    },
    goToNoticesPage () {
      this.$router.push({ name: 'Notices' })
    },
    clickNotice(notice) {
      this.$router.push({ name: 'Notices', query: { notice_id: notice.id } })
    },
  },
  mounted () {
    this.getNoticesPreview()
  }
}
</script>
