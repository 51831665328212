<template>
  <div>
    <div class='flex flex-col lg:flex-row justify-between items-stretch gap-4 p-4'>
      <div class='w-full flex lg:flex-col flex-row gap-4'>
        <div class='flex-grow rounded-xl flex flex-col lg:flex-row gap-y-4 lg:gap-x-4'>
          <div v-for='(largeItem, index) in largeItems' 
            :key='`largeItem-${index}`'
            class='flex-grow flex flex-col justify-center gap-2 rounded-xl'
            :style='largeItem.cssStyle'
            @click='goToItemExternal(largeItem.routeName)'>
            <img 
              :src='largeItem.iconImgUrl'
              class='block mx-auto'
              :style='largeItem.iconImgCssStyle' />
            <h2 
              class='text-center uppercase text-base lg:text-xl cursor-pointer'
              :style='largeItem.titleCssStyle'>{{ largeItem.title }}</h2>
          </div>
        </div>
        <div class='lg:w-full w-1/2 flex-grow-0 flex-shrink-0 flex lg:flex-row flex-col gap-2'>
          <div v-for='(item, index) in smallItems' 
            :key='`item-${index}`'
            class='w-full px-2 lg:px-4 py-3 flex lg:flex-col flex-row lg:justify-center justify-start lg:gap-y-1 gap-x-1 font-semibold items-center rounded-xl bg-white'
            :style='item.cssStyle'
            @click='goToItemExternal(item.routeName)'>
            <img 
              :src='item.iconImgUrl'
              class='block flex-grow-0 flex-shrink-0 h-8 w-8'
              :style='item.iconImgCssStyle' />
            <h2 
              class='text-left uppercase text-xs cursor-pointer'
              :style='item.titleCssStyle'>{{ item.title }}</h2>
          </div>
        </div>
      </div>
      <div class='lg:w-2/3 w-full flex-grow-0 flex-shrink-0'>
        <notices-preview-list 
          class='px-4 py-2 bg-white rounded-xl'
          title="Notices"/>
      </div>
    </div>
  </div>
</template>

<script>
import NoticesPreviewList      from '@/components/NoticesPreviewList.vue'
export default {
  name: 'Gbcc2024MainSubItems',
  components: {
    NoticesPreviewList,
  },
  props: [
    'items',
  ],
  computed: {
    smallItems () {
      return this.items.filter(item => item.size !== 'large')
    },
    largeItems () {
      return this.items.filter(item => item.size === 'large')
    }
  },
  methods: {
    goToItemInternal (routeName) {
      this.$router.push({ name: routeName })
    },
    goToItemExternal (path) {
      window.open(path, '_blank')
    },
  },
}
</script>
